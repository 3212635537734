<template>
  <!-- App.vue -->
  <div id="warsaw-media-app">
    <v-app dark>
      <v-main class="align-center">
        <router-view></router-view>
      </v-main>
      <v-footer app color="transparent" class="vw-footer">
      </v-footer>
    </v-app>
    <div class="app-background"></div>
  </div>
</template>

<script>
export default {
  name: "AppBar",
  data: () => ({
    langs: ["en", "de"],
    refreshing: false,
    registration: null,
    updateExists: false,
  }),
  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },
  destroyed() {
    document.removeEventListener("swUpdated", this.updateAvailable);
  },
  methods: {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },

    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    setLang(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>
<style lang="scss">
.vw-app-logo {
  vertical-align: middle;
  height: 40px;
  position: absolute;
  right: 60px;
  top: 38px;
  z-index: 9999;
  img {
    height: 40px;
    vertical-align: middle;
  }
}
.vw-header {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.vw-header-language-switcher {
  padding: 38px 28px 28px 60px;
  .vw-app-selected-language {
  }
  .lang-item {
    display: inline-block;
    box-shadow: 0 0 1px 2px rgba(255, 255, 255, 0);
    transition: all 0.4s ease;
    &:hover {
      box-shadow: 0 0 30px -5px rgba(255, 255, 255, 0.6);
    }
    &:focus,
    &:active {
      box-shadow: 0 0 30px -5px rgba(255, 255, 255, 0.6);
    }
    &.active {
      //box-shadow: 0 0 30px -5px rgba(255, 255, 255, 0.8);
      box-shadow: 0 0 30px -2px rgba(170, 209, 255, 0.9);
    }

    img {
      vertical-align: middle;
      max-width: 68px;
    }
    & + .lang-item {
      margin-left: 50px;
    }
  }
}
.vw-footer {
  opacity: 0.5;
  position: relative;
  z-index: 999;
  font-size: 14px;
}
</style>
